import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import Header from "./common/Header";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import Footer from "./common/Footer";

function Fund() {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [tqqqData, setTqqqData] = useState([]);
  const [isDataReady, setIsDataReady] = useState(false);

  useEffect(() => {
    // 利用 URL 查询参数判断是否已经刷新过一次
    const url = new URL(window.location.href);
    const hasReloaded = url.searchParams.get("reloaded");

    if (!hasReloaded) {
      // 3秒后刷新一次页面，并在 URL 中添加 reloaded 参数
      setTimeout(() => {
        url.searchParams.set("reloaded", "true");
        window.location.href = url.toString();
      }, 3000);
    }

    async function fetchAndProcessData(file) {
      const response = await fetch(file);
      if (!response.ok) {
        throw new Error("Network response was not ok " + response.statusText);
      }
      const reader = response.body.getReader();
      const result = await reader.read();
      const decoder = new TextDecoder("utf-8");
      const csv = decoder.decode(result.value);
      return Papa.parse(csv, {
        header: true,
        skipEmptyLines: true,
      });
    }

    function processFundData(parsedData) {
      const initialProfit = parsedData.find(
        (row) =>
          new Date(row["Date/Time"]).getTime() ===
          new Date("2023-01-09T20:30:00").getTime()
      )["Cum. Profit USD"];

      return [
        { x: t("Fund:initialValue"), y: 1 },
        ...parsedData
          .filter(
            (row) =>
              new Date(row["Date/Time"]) > new Date("2023-01-09T20:30:00")
          )
          .sort((a, b) => new Date(a["Date/Time"]) - new Date(b["Date/Time"]))
          .map((row) => {
            const profitChange =
              parseFloat(row["Cum. Profit USD"]) - parseFloat(initialProfit);
            return {
              x: row["Date/Time"],
              y: profitChange / parseFloat(initialProfit) + 1,
            };
          }),
      ];
    }

    function processTQQQData(parsedData) {
      const initialProfit = parsedData.find(
        (row) =>
          new Date(row["Date/Time"]).getTime() ===
          new Date("2024-01-11T20:30").getTime()
      )["Cum. Profit USD"];

      return [
        { x: t("Fund:initialValue"), y: 1 },
        ...parsedData
          .filter(
            (row) => new Date(row["Date/Time"]) > new Date("2024-01-11T20:30")
          )
          .sort((a, b) => new Date(a["Date/Time"]) - new Date(b["Date/Time"]))
          .map((row) => {
            const profitChange =
              parseFloat(row["Cum. Profit USD"]) - parseFloat(initialProfit);
            return {
              x: row["Date/Time"],
              y: profitChange / parseFloat(initialProfit) + 1,
            };
          }),
      ];
    }

    async function prepareData() {
      try {
        const [fundParsed, tqqqParsed] = await Promise.all([
          fetchAndProcessData("./data/fund.csv"),
          fetchAndProcessData("./data/2024TQQQ.csv"),
        ]);

        const processedFundData = processFundData(fundParsed.data);
        const processedTQQQData = processTQQQData(tqqqParsed.data);

        setData(processedFundData);
        setTqqqData(processedTQQQData);
        setIsDataReady(true);
      } catch (error) {
        console.error("Error fetching or parsing the data", error);
      }
    }

    prepareData();
  }, [t]);

  // Recharts图表配置
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip bg-black bg-opacity-80 shadow-[0_0px_10px_2px_rgba(255,255,255,0.5)] text-white p-3 rounded">
          <p className="label">{`${t("Fund:date")}${label}`}</p>
          <p className="intro">{`${t(
            "Fund:fundNetValue"
          )}${payload[0].value.toFixed(2)}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <Helmet>
        <title>AI-Fund - 人工智能基金 | New Horizon</title>
        <meta
          name="description"
          content="Explore the world of AI-Fund with New Horizon. Gain comprehensive insights into Artificial Intelligence funding, industry trends, and investment opportunities. Join us in navigating the dynamic landscape of AI technology and finance."
        />
        <meta
          name="keywords"
          content="AI-Fund, Artificial Intelligence, Investment, New Horizon, 人工智能基金,"
        />
        <link rel="canonical" href="https://nhwt.tech/fund" />
      </Helmet>

      <Header />
      <div className="mx-auto pt-5 px-8 pb-[80px] rounded-lg bg-black text-white font-mono text-base">
        <div className=" text-white mb-5 flex items-center justify-start">
          <h1>Awakened Fund</h1>
        </div>
        {isDataReady ? (
          <>
            <div className="text-[16px] text-white mb-5 flex items-center justify-start">
              <h2>2024</h2>
            </div>
            <ResponsiveContainer width="100%" height={400}>
              <AreaChart
                data={tqqqData}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
              >
                <defs>
                  <linearGradient id="colorProfit" x1="0" y1="0" x2="1" y2="1">
                    <stop offset="5%" stopColor="#1890FF" stopOpacity={0.3} />
                    <stop offset="95%" stopColor="#1890FF" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="x" tick={{ fontSize: 12 }} />
                <YAxis
                  tick={{ fontSize: 12 }}
                  domain={["auto", "auto"]}
                  tickFormatter={(value) => value.toFixed(2)}
                  tickLine={false}
                  axisLine={false}
                />
                <CartesianGrid
                  strokeDasharray="0"
                  vertical={false}
                  stroke="#333333"
                />
                <Tooltip content={<CustomTooltip />} />
                <Area
                  type="monotone"
                  dataKey="y"
                  stroke="#1890FF"
                  fillOpacity={1}
                  fill="url(#colorProfit)"
                />
              </AreaChart>
            </ResponsiveContainer>
            <div className="text-[16px] text-white mb-5 flex items-center justify-start">
              <h2>2023</h2>
            </div>
            <ResponsiveContainer width="100%" height={400}>
              <AreaChart
                data={data}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
              >
                <defs>
                  <linearGradient id="colorProfit" x1="0" y1="0" x2="1" y2="1">
                    <stop offset="5%" stopColor="#1890FF" stopOpacity={0.3} />
                    <stop offset="95%" stopColor="#1890FF" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="x" tick={{ fontSize: 12 }} />
                <YAxis
                  tick={{ fontSize: 12 }}
                  domain={["auto", "auto"]}
                  tickFormatter={(value) => value.toFixed(2)}
                  tickLine={false}
                  axisLine={false}
                />
                <CartesianGrid
                  strokeDasharray="0"
                  vertical={false}
                  stroke="#333333"
                />
                <Tooltip content={<CustomTooltip />} />
                <Area
                  type="monotone"
                  dataKey="y"
                  stroke="#1890FF"
                  fillOpacity={1}
                  fill="url(#colorProfit)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </>
        ) : (
          <div className="text-center text-white h-[800px] flex items-center justify-center">
            Loading data, please wait...
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Fund;
